<template>
  <base-page
    :title="$t('arDigitalManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.poolList"
      :defaultParams="params"
      :columns="columns"
      :multiple="true"
      :highlightCurrentRow="false"
      v-model="tableRows"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:修改 -->
        <!-- <el-button
          v-if="$page.showBtn('AR_DIGTAL_UPDATE')"
          type="text"
          @click="editMethod(row)"
        >
          {{ $t('functionEnum.AR_DIGTAL_UPDATE') }}
        </el-button> -->
        <!-- lang:删除 -->
        <el-button
          v-if="$page.showBtn('AR_DIGTAL_DELETE')"
          type="text"
          @click="deleteMethod(row)"
        >
          {{ $t('functionEnum.AR_DIGTAL_DELETE') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-import
      v-if="showDialogImport"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.poolImportArSx"
      :template="importTemplate"
      @cancel="showDialogImport = false"
      @confirm="importConfirm"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    return {
      importTemplate: `/template/${lang}/arDigital.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('arDigitalManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        type: 'AR_DIGITAL_DISPLAY',
      },
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:AR数显码名称
        {
          label: this.$t('arDigitalManage.name'),
          prop: 'name',
        },
        // lang:url地址
        {
          label: this.$t('arDigitalManage.code'),
          prop: 'code',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '150px',
        },
      ],
      // 表单页
      showDetail: false,
      // 导入
      showDialogImport: false,
    }
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 表格按钮修改
    editMethod(row) {
      this.tableRow = row
      this.$store.commit('updateCmd', 'edit')
      this.showDetail = true
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (
        method !== 'add' &&
        method !== 'import' &&
        method !== 'export' &&
        !this.tableRow
      ) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'import':
          this.showDialogImport = true
          break
        case 'export':
          this.multipleExport()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod(row) {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.poolDelete,
            data: row,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 导入成功回掉
    importConfirm() {
      this.showDialogImport = false
      this.search()
    },
    // 多个导出
    multipleExport() {
      if (!this.tableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      const ids = this.tableRows.map((item) => item.id)
      this.exportMethod(ids.join(','))
    },
    // 导出
    exportMethod(ids) {
      const params = {
        qrcodeSize: 170,
        logoSize: 10,
        fontWidth: 12,
        clo: 3,
        pageCount: 3,
        id: ids,
      }
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.poolExportArSxCode,
        // lang:AR数显码
        name: this.$t('arDigitalManage.arName'),
        type: '.docx',
        data: params,
      })
    },
  },
}
</script>